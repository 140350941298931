import Link from 'next/link';

import { SocialIcons } from '../social-icons';

import styles from './footer.module.scss';

export const Footer: React.FC<{ className?: string }> = ({
  className = '',
}) => (
  <footer className={`${className} ${styles.footer}`}>
    <span className={styles.corpInfo}>© 2024 Confabulation Corporation</span>

    <SocialIcons />
    <div className={styles.rightContainer}>
      <Link className={styles.termsLink} href="https://www.ollie.ai/terms-of-service">
        Terms of Service
      </Link>
      <Link className={styles.termsLink} href="https://www.ollie.ai/privacy-policy">
        Privacy Policy
      </Link>
      <Link className={styles.termsLink} href="https://www.ollie.ai/cookie-policy">
        Cookie Policy
      </Link>
    </div>
  </footer>
);
